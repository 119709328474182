import TYPES from '@/types';

import format from 'date-fns/format';

import { currencyFormat } from '@/vue-app/utils/currency';

// application
import {
  SearchTransactionsByRangeQuery,
} from '@/modules/my-investment/allianz/transaction/application/queries';

// Domain
import {
  OngoingContributionsDto,
} from '@/modules/my-investment/allianz/transaction/domain/dtos/ongoing-contributions-dto';
import {
  TransactionEntity,
} from '@/modules/my-investment/allianz/transaction/domain/entities/transaction-entity';
import Translator from '@/modules/shared/domain/i18n/translator';
import Inject from '@/modules/shared/domain/di/inject';
import { Values } from '@/modules/shared/domain/i18n/types';
import { DateFormatter } from '@/modules/shared/domain/date-formatters';
import { Router } from '@/modules/shared/domain/router';

export default class WealthPendingMovementsBannerViewModel {
  @Inject(TYPES.SEARCH_ALLIANZ_TRANSACTIONS_BY_RANGE_QUERY)
  private readonly search_allianz_transactions_query!: SearchTransactionsByRangeQuery;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.DATE_FORMATTER)
  readonly date_formatter!: DateFormatter;

  @Inject(TYPES.ROUTER)
  private readonly router!: Router;

  readonly i18n_namespace = 'components.banners.wealth_pending_movements';

  ongoing_contributions: OngoingContributionsDto = {
    total: 0,
    amount: '',
    deadline_date: '',
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  goToPendingRequests = () => {
    this.router.navigate('sowos-wealth?show_pending_transactions');
  }

  setOnGoingContributions = (requests: TransactionEntity[]) => {
    const contribution_types = ['RecurringContribution', 'Deposit', 'PaymentRetry'];
    const transaction_status = ['Pending', 'Sent'];

    const contributions = requests.filter(
      (request) => contribution_types.indexOf(request
        .transaction_type.name) > -1 && transaction_status.indexOf(request
        .transaction_status.name) > -1,
    );

    this.ongoing_contributions.total = contributions.length;
    if (contributions.length === 1) {
      this.ongoing_contributions.amount = currencyFormat(contributions[0].amount);
      this.ongoing_contributions.deadline_date = contributions[0].reflected_date
        ? this.date_formatter.formatDate(contributions[0].reflected_date, 'DD MMM') : '';
    } else {
      const total_amount = contributions.reduce(
        (total, contribution) => total + contribution.amount, 0,
      );
      this.ongoing_contributions.amount = currencyFormat(total_amount);
      this.ongoing_contributions.deadline_date = '';
    }
  }

  loadTransactions = async () => {
    const initial_date = new Date();
    initial_date.setDate(initial_date.getDate() - 5);
    initial_date.setHours(0, 0, 0, 0);
    const initial_date_iso = format(initial_date.toISOString(), 'YYYY-MM-DDThh:mm:ss');
    const final_date = new Date();
    final_date.setHours(24, 0, 0, 0);
    const final_date_iso = format(final_date.toISOString(), 'YYYY-MM-DDThh:mm:ss');
    const params = {
      initial_date: initial_date_iso,
      final_date: final_date_iso,
    };
    const requests = await this.search_allianz_transactions_query
      .execute(params);
    if (requests) {
      this.setOnGoingContributions(requests);
    }
  }

  addCreatePendingTransactionEventListener = () => {
    window.addEventListener('allianz.create.pending.transaction', this.loadTransactions);
  }

  addCancelPendingTransactionEventListener = () => {
    window.addEventListener('allianz.cancel.pending.transaction', this.loadTransactions);
  }

  removeListeners = () => {
    window.removeEventListener('allianz.create.pending.transaction', this.loadTransactions);
    window.removeEventListener('allianz.cancel.pending.transaction', this.loadTransactions);
    this.ongoing_contributions.total = 0;
  }

  initialize = async () => {
    this.addCreatePendingTransactionEventListener();
    this.addCancelPendingTransactionEventListener();
    await this.loadTransactions();
  }
}
