





















































import { Component, Vue } from 'vue-property-decorator';
import WealthPendingMovementsBannerViewModel
  from '@/vue-app/view-models/components/banners/wealth-pending-movements-banner-view-model';

@Component({
  name: 'WealthPendingMovementsBanner',
})
export default class WealthPendingMovementsBanner extends Vue {
  banner_view_model = Vue.observable(new WealthPendingMovementsBannerViewModel());

  created() {
    this.banner_view_model.initialize();
  }

  destroyed() {
    this.banner_view_model.removeListeners();
  }
}
